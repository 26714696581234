import {ValueType} from "../ps-models/line-items";

export interface LineItemField {
    name: string,
    key: string,
    value: ValueType,
    label?: string,
    isDynamic?: boolean
    code?: string
}

export type LineItemFieldFilter = {name: string, value: ValueType[] | null, applicableAsAnd?: boolean}

export const TIMED_RAW_LINE_ITEM_TYPE = "TimedRawLineItem";

export const TIMED_CALCULATED_LINE_ITEM_TYPE = "TimedCalculatedLineItem";

export const PARAMETER_LINE_ITEM_TYPE = 'ParameterLineItem'

export const REPEATING_LINE_ITEM = "RepeatingLineItem"

export const TEMPLATE_LINE_ITEM_TYPE = 'TemplateLineItem'

export type LineItemTypes = typeof TIMED_RAW_LINE_ITEM_TYPE | typeof TIMED_CALCULATED_LINE_ITEM_TYPE
    | typeof PARAMETER_LINE_ITEM_TYPE | typeof REPEATING_LINE_ITEM | typeof TEMPLATE_LINE_ITEM_TYPE;