import {LineItemDataSet} from "../LineItemDataSet";
import {TimeIndex} from "../Time.model";
import {
  buildMonthlyTimeDef,
  buildTimedCalculatedLineItem,
  isTimedLineItem, LineItem, LineItemsFieldSet,
  LineItemValue, NO_DATA,
  ParameterLineItem,
  ValueType
} from "../line-items";
import {normalizeString} from "../line-item-utils/coding.utils";
import {PartialExecution} from "./PartialExecution";
import {truncateTimeStamp} from "../TimeGranularity";
import {TimeRange, TimeUnits} from "../Time.types";
import {v4 as uuidv4} from "uuid";
import {LineItemStoreLogs} from "../LineItemStoreLogs";
import {values} from "ramda";
import {LineItemField} from "../../ps-types";
import {TimeZoneContext} from "../TimeZoneContext.types";


export class LineItemsStoreExecution implements PartialExecution {
    private cache : Record<string, any> = {}
    public readonly executionId;

     constructor(private dataSet: LineItemDataSet, private defaultTimeIndex: TimeIndex, public name: string, private timeZoneContext?: TimeZoneContext, private logs: LineItemStoreLogs = new LineItemStoreLogs()) {
        this.executionId =  `${uuidv4().substring(0, 4)}`;
    }

    setCache<T>(key: string, value: T) {
        this.cache[key] = value;
        return value;
    }

    getCache(key: string) {
        return this.cache[key];
    }

    getDataSet(): LineItemDataSet {
        return this.dataSet;
    }

    getTimeIndex(): TimeIndex {
        return this.defaultTimeIndex;
    }

    getTimeZoneContext(): TimeZoneContext | undefined {
         return this.timeZoneContext;
    }

    getLogs(): LineItemStoreLogs {
         return this.logs;
    }

    processDynamicFields() {
        values(this.dataSet.getLineItems()).forEach(li => {
            li.fields.getFields().forEach(field => {
                if(field.isDynamic && field.code && !field.value) {
                    let cl = buildTimedCalculatedLineItem(
                        `Dynamic Field: ${field.name}`,
                        buildMonthlyTimeDef("first"),
                        field.code,
                        li.fields
                    );
                    let timeIndex = this.getTimeIndex();

                    let value = cl.getValue(timeIndex.startDate.getTime(), this).value;
                    field.value = value;
                }
            })
        })
    }

   getField(lineItemName: string, name: string): LineItemField | undefined {
        const lineItem = this.dataSet.getLineItem(lineItemName);
        if (lineItem === undefined) {
          return;
        }

        let field =  lineItem.fields.getField(name);

        if(field === undefined) {
            return;
        }

        if(field.isDynamic && field.code && !field.value) {
          let cl = buildTimedCalculatedLineItem(
            `Dynamic Field: ${field.name}`,
            buildMonthlyTimeDef("first"),
            field.code,
            lineItem.fields
          );

          let timeIndex = this.getTimeIndex();

          let value = cl.getValue(timeIndex.startDate.getTime(), this).value;
          field.value = value;

          return {
            ...field,
            name: field.name,
            value
          }
        }


        return field;

   }
  getFieldValues(fieldName: string) {
     //Get fields for each line item
      let fieldValues = values(this.getDataSet().getLineItems())
        .map(li => this.getFieldStr(li.name, fieldName))
        .filter(v => !!v) as ValueType[];

      return Array.from(new Set<ValueType>(fieldValues));
  }

  getFieldStr(lineItemName: string, fieldName: string) {
    let tmpFieldSet = new LineItemsFieldSet();
    let field = this.getField(lineItemName, fieldName);
    //Overrides value
    if(field !== undefined) {
      tmpFieldSet.addField(fieldName, field.value);
    }

    return tmpFieldSet.getFieldStr(fieldName);
  }

   hasFieldValue(lineItemName: string, fieldName: string, value: string) {
     let tmpFieldSet = new LineItemsFieldSet();
     let field = this.getField(lineItemName, fieldName);
     //Overrides value
     if(field !== undefined) {
       tmpFieldSet.addField(fieldName, field.value);
     }

     return tmpFieldSet.hasFieldValue(fieldName, value);
   }

   getFieldMap(lineItemName: string) {
      let fieldSet = this.dataSet.getLineItem(lineItemName)?.fields.getFieldMap() ?? {};

      for(let fieldKey in fieldSet) {

        let field = this.getField(lineItemName, fieldKey)
        if(field) {
          fieldSet[fieldKey] = field;
        }

      }

      return fieldSet
   }

  getLineItemValue(lineItem: LineItem, timeStamp: number, targetGranularity?: TimeUnits): LineItemValue {

    if(lineItem instanceof ParameterLineItem) {
        return lineItem.getValue();
    }


    if(!targetGranularity) {
      targetGranularity = this.getTimeIndex().getUnit();
    }

    let timeQuery = timeStamp; // truncateTimeStamp(targetGranularity, timeStamp);

    return lineItem.getValue(timeQuery, this, targetGranularity);
  }

    getTimedLineItemValue(liName: string, timeStamp: number, targetGranularity?: TimeUnits): LineItemValue {
        const lineItemName = normalizeString(liName);
        const lineItem = this.dataSet.getLineItem(lineItemName);

        if (lineItem === undefined) {
            return NO_DATA;
        }

        if(!targetGranularity) {
            targetGranularity = this.getTimeIndex().getUnit();
        }

        let timeQuery = truncateTimeStamp(targetGranularity, timeStamp);


        if(lineItem instanceof ParameterLineItem) {
            return lineItem.getValue();
        }

        if(!isTimedLineItem(lineItem)) {
            throw new Error(`LineItem ${lineItem.name}  is not a TimedLineItem but a ${lineItem.constructor.name}`)
        }


        return lineItem.getValue(timeQuery, this, targetGranularity);
    }


}