import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import {SectionService} from "../../Section";
import React, {ReactNode} from "react";
import { Menu } from "semantic-ui-react";
import {NewAuthWrapper} from "../../../auth";
import {Route} from "react-router-dom";
import {Layout} from "../../layout";
import {Loans} from "./Loans";
import {Projects} from "../../projects/Projects.page";
import {LoanProjects} from "./LoanProjects";
import "./Widgets"
import Home from "./Home";

registerModule('ESG', new class extends DefaultModule {

  overrideUIStrings() {
    return {
      "Asset Management": "Portfolio Management",
      "Borrower": "Portfolio",
      "Loan": "Financing Partner",
      "Upcoming Loan Payments": "Upcoming Payments to Financing Partner",
      "Borrower Summary": "Portfolio Summary"
    }
  }

  getRoutes(): React.ReactNode {
      return <>
        <Route exact path='/ps/:companyId/ext/loans'
               render={ () => <NewAuthWrapper>
                 <Layout >
                     <Loans />
                 </Layout>
               </NewAuthWrapper> } />
        <Route exact path='/ps/:companyId/ext/projects/:loanId'
               render={ () => <NewAuthWrapper>
                 <Layout >
                   <LoanProjects />
                 </Layout>
               </NewAuthWrapper> } />
      </>
  }

  overrideDashboardSections(sectionService: SectionService) {

    //Adds home page
    sectionService.addSection({
      key: 'home',
      title: 'Home',
      order: -10,
      render: () => <Home />
    });

    sectionService.setHomeSection('home')

    return sectionService;
  }


})