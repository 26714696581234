import {useHistory, useLocation} from "react-router-dom";
import {filter, last} from "ramda";
import {useCompanyId, useCurrentUser} from "../core";
import {Menu} from "semantic-ui-react";
import React, {ReactNode} from "react";
import {useAMCompanyHome} from "./config";
import {getAmProjectConfig} from "../ps-models";
import {loadCompanyModule} from "./builder/DashboardConfigServiceRegistry";

export function AssetsManagementMenu() {
  const history = useHistory();

  let currentUser = useCurrentUser();

  let path = last(useLocation().pathname.split("/"));

  const companyId = useCompanyId();

  const home = useAMCompanyHome();


let menuItems: { key: string, menuItem: ReactNode }[] = [
  {
    key: 'dashboard',
    menuItem: (
      <Menu.Item
        key="dashboard"
        active={path === 'dashboard'}
        onClick={() => history.push(home)}
      >
        Dashboard
      </Menu.Item>
    )
  },
  {
    key: 'boardBuilder',
    menuItem: currentUser?.isAdmin() && (
      <Menu.Item
        key="boardBuilder"
        active={path === 'Board Builder'}
        onClick={() => history.push(`/ps/${companyId}/am/board-builder`)}
      >
        Board Builder
      </Menu.Item>
    )
  },
  {
    key: 'projects',
    menuItem: (((!currentUser?.company || currentUser?.isAdmin()) ? true : getAmProjectConfig(currentUser?.company).namespace !== "ESG") &&
      <Menu.Item
        key="projects"
        active={path === 'projects'}
        onClick={() => history.push(`/ps/${companyId}/am/projects`)}
      >
        Projects
      </Menu.Item>
    )
  },
  {
    key: 'reports',
    menuItem: (
        ((!currentUser?.company || currentUser?.isAdmin()) ? true : getAmProjectConfig(currentUser?.company).namespace !== "ESG") &&
      <Menu.Item
        key="reports"
        active={path === 'reports'}
        onClick={() => history.push(`/ps/${companyId}/am/reports`)}
      >
        Reports
      </Menu.Item>
    )
  },
  {
    key: 'invoices',
    menuItem: (currentUser?.isAdmin() || (currentUser?.company && (getAmProjectConfig(currentUser?.company).namespace === "KelvinClearGen" || getAmProjectConfig(currentUser?.company).namespace === "ESG"))) && (
      <Menu.Item
        key="invoices"
        active={path === 'invoices'}
        onClick={() => history.push(`/ps/${companyId}/am/invoices`)}
      >
        Invoicing
      </Menu.Item>
    )
  },
  {
    key: 'keyManagement',
    menuItem: currentUser?.isAdmin() && (
      <Menu.Item
        key="key-management"
        active={path === 'key-management'}
        onClick={() => history.push(`/ps/${companyId}/key-management`)}
      >
        IOT API Setup
      </Menu.Item>
    )
  },
  {
    key: 'integrations',
    menuItem: currentUser?.isAdmin() && (
      <Menu.Item
        key="integrations"
        active={path === 'integrations'}
        onClick={() => history.push(`/ps/${companyId}/assets-management/integrations`)}
      >
        Integrations
      </Menu.Item>
    )
  },
  {
    key: 'lineItemsEditor',
    menuItem: currentUser?.isAdmin() && (
      <Menu.Item
        key="line-items-editor"
        active={path === 'line-items-editor'}
        onClick={() => history.push(`/ps/${companyId}/assets-management/line-items-editor`)}
      >
        Line Items Editor
      </Menu.Item>
    )
  },
  {
    key: 'lineItemsBatchUpdater',
    menuItem: currentUser?.isAdmin() && (
      <Menu.Item
        key="line-items-batch-updater"
        active={path === 'line-items-batch-updater'}
        onClick={() => history.push(`/ps/${companyId}/assets-management/line-items-batch-updater`)}
      >
        Line Items Batch Updater
      </Menu.Item>
    )
  }
];

let filteredMenuItems = menuItems.filter(item => !!item.menuItem);


  menuItems = loadCompanyModule().overrideMainMenu(path || "", filteredMenuItems, companyId, history)

  return (
    <Menu>
      {Object.values(menuItems).map(item => item.menuItem)}
    </Menu>
  )
}