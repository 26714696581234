import React, {useEffect, useState} from 'react';
import {Container, Header, Segment, Button,Label, Icon, Grid, SegmentGroup} from 'semantic-ui-react';
import {useHistory} from "react-router-dom";
import {PS_URLS, useCompanyId} from "../../../core";
import {useMessages} from "../../../ui/MessagesProvider";
import {AMCompanyModel} from "../../../ps-types";
import {getAmCompanyModelDownloadUrl, getAmCompanyModels} from "../../../company/company.client";

const Dashboard = () => {
  const companyId = useCompanyId()



  return (<>
          <Container>
              <Segment>

                  {/*<Segment placeholder>*/}
                  {/*  <Header as='h4'>Please watch this brief video to learn how to extract the most value out of Perl Street for Impact Reporting and more.</Header>*/}
                  {/*  /!* Assuming you have a video component or placeholder here *!/*/}
                  {/*</Segment>*/}

                  <Header as='h2' color="purple">Active Subscriptions</Header>
                  <SegmentGroup divided relaxed>
                      <Subscription
                          title="Portfolio Impact Reporting"
                          description="Report on your ESG Impact across portfolios."
                          url={`/ps/${companyId}/am/dashboard/impact-summary`}
                          accessKind={'Restricted'}
                      />
                      <div style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          margin: '0px 45px 10px 0px'
                      }}>
                      <ESGDashboardButtons companyId={companyId} />
                      </div>
                      <Subscription
                          title="Portfolio Rollups"
                          description="Aggregate financial and operational performance across portfolios."
                          url={`/ps/${companyId}/am/dashboard/portfolio-rollups`}
                      />
                      <Subscription
                          title="Investor and Management Reporting"
                          description="Provide granular view/edit access to specific portfolios for dynamic reporting that saves time."
                          url={`/ps/${companyId}/am/dashboard/investor-andmanagementreporting`}
                      />
                      <Subscription
                          title="Invoicing and Payments"
                          description="Manage customer invoicing - including automatic updates to/via most major Accounting/ERP software."
                          url={`/ps/${companyId}/am/invoices`}
                      />

                      <Subscription
                          title="Loan Management"
                          description="Manage your debt schedules and borrowing base reporting across projects and portfolios."
                          url={`/ps/${companyId}/am/dashboard/loan-management`}
                      />

                  </SegmentGroup>
              </Segment>
          </Container>
  </>

  );
};

interface SubscriptionProps {
  title: string;
  description: string;
  accessKind?: 'Full' | 'Restricted';
  restrictedAccess?:boolean;
  url?: string;
}

const Subscription: React.FC<SubscriptionProps> = ({ title, description, accessKind, url }) => {

  const history = useHistory();
  const handleClick = () => {
    history.push(url!)
  };

  return (
    <Segment style={{ boxShadow: 'none' }}>
      <Grid columns={2} verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h3' style={{fontSize: 18}}>
              {accessKind === 'Full' && <Icon name='check circle outline' color='green' size='small' />}
              {title}
              {accessKind === 'Restricted' && <Label color='orange' horizontal>
                    Limited Trial Access
              </Label>}
            </Header>
            <p>{description}</p>
          </Grid.Column>
          <Grid.Column width={6} textAlign='right'>
            <Button primary style={{ marginRight: '30px' }}
              onClick={handleClick}
            >See It in Action</Button>
            {/*<Button secondary={active} disabled={!active}>Upgrade</Button>*/}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

function ESGDashboardButtons({companyId}: {companyId: string}){
    let {clear, error} = useMessages();
    const history = useHistory();
    const [models, setModels] = useState<AMCompanyModel[]>([]);
    useEffect(()=>{
        getAmCompanyModels(companyId).then((res)=>{
            setModels(res);
        }).catch((err)=>{
            console.error(err);
            error("Something went wrong. Please try after sometime.")
        })
    }, [companyId])

    const downloadModel = async (companyModelData: AMCompanyModel) => {
        getAmCompanyModelDownloadUrl(companyId, companyModelData.id).then(async (res) => {
            window.open(res.url, "_blank")
        }).catch((err) => {
            console.error(err);
            error("Unable to download the model at the moment. Please try after sometime.")
        });
    }

    return <>
        <Button floated="right" content={'Download Template'} disabled={models?.length === 0} onClick={()=>{
            if(models?.length >0){
                return downloadModel(models?.[0]);
            }
        }}/>
        <Button primary floated="right" content={"Upload PV Generation Template"} onClick={()=>history.push(PS_URLS(companyId).esgTemplateUpload())}/>
    </>
}
export default Dashboard;
