import {LineItemsStore, StoreQuery, TimeColumn} from "../ps-models/lineitems-store";
import {values} from "ramda";
import {Message, Table} from "semantic-ui-react";
import {formatDateByTimeUnit} from "../ps-models";
import React from "react";
import {useStrings} from "./ui-strings";

export function IncomingPayments({store, includeLoanColumn = false}: { store: LineItemsStore, includeLoanColumn?: boolean }) {
  const st = useStrings();

  let result = store.query(StoreQuery
    .byNames(['Projected - External Debt - Interest', 'External Debt - Principal Repayment'], true)
    .and(StoreQuery.withField('source_inputdata-borrower'))
  );

  //source_inputdata-borrower

  let nextPayments: { date: Date, value: string, paymentType: string, lender: string, loan?: string }[] = [];
  for (let row of result.rows) {
    for (let paymentType of ['Projected - External Debt - Interest', 'External Debt - Principal Repayment']) {
        for (let cell of values(row)) {
          const lenderCell = result.rowColumnValue(row, "fields.source_Input Data-Borrower");
          const loanCell = result.rowColumnValue(row, "fields.source_Input Data-Loan");
          if (!lenderCell) {
            continue;
          }
          if(includeLoanColumn && !loanCell){
            continue;
          }
          let timeCol = (result.columnById(cell.columnId) as TimeColumn);
          let today = new Date().getTime();
          if (timeCol && timeCol.time && today < timeCol.time && cell.value !== 0) {
            nextPayments.push({
              date: new Date(timeCol.time),
              value: cell.text,
              paymentType,
              lender: lenderCell as string,
            ...(includeLoanColumn ? {loan: loanCell as string} : {}),
            });
            break;
          }
        }
    }
  }

  // sort by date
  nextPayments.sort((a, b) => a.date.getTime() - b.date.getTime());


  return (<div>
      <Message warning style={{marginTop: "15px", marginBottom: "15px"}}>
        <div><strong>{st("Upcoming Loan Payments")}</strong></div>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Payment Type</Table.HeaderCell>
              <Table.HeaderCell>Next Payment Date</Table.HeaderCell>
              {includeLoanColumn && <Table.HeaderCell>{st("Loan")}</Table.HeaderCell>}
              <Table.HeaderCell>{st("Borrower")}</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>

            </Table.Row>
          </Table.Header>
          <Table.Body>
            {nextPayments.map((p, i) => (<Table.Row>
                <Table.Cell>{p.paymentType}</Table.Cell>
                <Table.Cell>{formatDateByTimeUnit(p.date, "days")}</Table.Cell>
                {includeLoanColumn && <Table.Cell>{p.loan}</Table.Cell>}
                <Table.Cell>{p.lender}</Table.Cell>
                <Table.Cell>{p.value}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Message></div>
  );
}