import React, {useMemo, useState} from 'react';
import {indexBy, values} from "ramda";
import {Button, Icon, Input, Segment, Table} from "semantic-ui-react";
import {LineItemField, PARAMETER_LINE_ITEM_TYPE} from "../../ps-types";
import {authStorage} from "../../auth";
import {getAmProjectConfig} from "../../ps-models";
import {usePSQuery} from "../../lineitems-store/LineItemsStore.hook";
import {pQuery} from "../../ps-models/lineitems-store";
import {LoadingBlock} from "../../ui/Loading";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import {ParameterLineItem} from "../../ps-models/line-items";

export interface EditorParam {
  name: string;
  value: string;
}

interface Props {
  params: Record<string, EditorParam>;
  onUpdate: (updatedData: Record<string, EditorParam>, deleted: string[]) => void;
  variant?: 'autocomplete' | 'textInput';
}

interface ParameterValueOption {label: string, value: string}

export const MultiParameterEditor: React.FC<Props> = ({ params, onUpdate, variant = "textInput" }) => {
  const [deleted, setDeleted] = useState<string[]>([]);
  const [messages, setMessages] = useState<Record<string, any>>({});

  let fields = values(params);

  const handleUpdate = (key: number, field: keyof LineItemField, value: string) => {
    setMessages(prev => ({...prev, [key]: {...prev[key], [field]: undefined} }));
    const updatedData = fields.map((item, index) => {
      if (index === key) {
        return { ...item, [field]: value };
      }
      return item;
    });

    onUpdate(indexBy(f=> f.name, updatedData), deleted);
  };

  const handleAdd = () => {
    onUpdate(indexBy(f=> f.name,
      fields.concat([{name: '', value: ''}])
    ), deleted);
  };

  const handleDelete = (keyToDelete: number) => {
    setMessages(prev => ({...prev, [keyToDelete]: undefined}));
    setDeleted(prev => prev.concat(fields[keyToDelete].name));
    // setFields(
    //   fields.filter((_, key) => key !== keyToDelete)
    // );
    let updatedData = fields.filter((_, key) => key !== keyToDelete);
    onUpdate(
      indexBy(f=> f.name, updatedData)
    , deleted)
  };

  return (
    <Segment>

      <h5>Project Parameters</h5>

      <Table basic='very' celled collapsing style={{width: "100%"}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell>
              <Button icon size="mini" onClick={handleAdd}>
                <Icon name='plus' />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {variant === 'textInput' && <ParameterInputCellsTextInputVariant
          fields={fields}
          handleDelete={handleDelete}
          messages={messages}
          handleUpdate={handleUpdate}
          />}
          {variant === 'autocomplete' && <ParameterInputCellsAutocompleteVariant
              fields={fields}
              handleDelete={handleDelete}
              messages={messages}
              handleUpdate={handleUpdate}
          />}
        </Table.Body>
      </Table>

    </Segment>
  );
};
 function buildOption(value: string): ParameterValueOption {
    return {label: value, value};
  }

interface InputComponentProps {
  messages:Record<string, any>
  fields: EditorParam[],
  handleUpdate: (key: number, field: keyof LineItemField, value: string)=>void,
  handleDelete:(key: number)=>void
}
export const ParameterInputCellsAutocompleteVariant = ({fields, messages, handleUpdate, handleDelete}: InputComponentProps) => {
  const company = authStorage.getCompany();
  let { collection } = getAmProjectConfig(company);
  let store = usePSQuery(collection, pQuery().lineItemsMetadataWithSingleTimeSlot()
      .withLineItemType(['ParameterLineItem'])
  );
  const paramLineItemValueMapping = useMemo(()=>{
    let paramLiValueMapping: Record<string, Set<string>> = {};
    if(!store){
      return flattenRecordSet(paramLiValueMapping);
    }
    store.getDataSet().getLineItemsByType(PARAMETER_LINE_ITEM_TYPE).forEach((li)=> {
      if(!paramLiValueMapping[li.canonicalName]){
        paramLiValueMapping = {...paramLiValueMapping, [li.canonicalName]: new Set<string>()};
      }
        paramLiValueMapping[li.canonicalName]
            .add((li as ParameterLineItem).getValue().value.toString());
    })
    return flattenRecordSet(paramLiValueMapping);
  }, [store]);

  if(!store) return <Table.Row><LoadingBlock /></Table.Row>;
  return <>{fields.map((fieldData, key) => (
      <Table.Row key={key}>
        <Table.Cell>
          <Autocomplete
              options={Object.keys(paramLineItemValueMapping).map((liName)=>({label: liName, value: liName}))}
              getOptionLabel={(option) => {
                if(typeof option === 'string'){
                  return option;
                } else {
                  return option.label
                }
              } }
              multiple={false}
              value={buildOption(fieldData.name)}
              freeSolo
              onChange={(event: any, newValue: string | ParameterValueOption | null)=>{
                  if(typeof newValue === 'string'){
                    handleUpdate(key, "name", newValue);
                  } else if(newValue?.value !== null && newValue?.value !== undefined){
                    handleUpdate(key, "name", newValue?.value);
                  }
                }
              }
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      helperText={!!(messages[key]?.name)}
                      error={!!(messages[key]?.name)}
                      required
                      size="small"
                  />
              )}
          />
        </Table.Cell>
        <Table.Cell>
          <Autocomplete
              options={paramLineItemValueMapping[fieldData.name]?.map((liVal)=>({label: liVal, value: liVal})) ?? []}
              getOptionLabel={(option) => {
                if(typeof option === 'string'){
                  return option;
                } else {
                  return option.label
                }
              } }
              value={buildOption(fieldData.value)}
              freeSolo
              onChange={(event: any, newValue: string | ParameterValueOption | null)=>{
                if(typeof newValue === 'string'){
                  handleUpdate(key, "value", newValue);
                } else if(newValue?.value !== null && newValue?.value !== undefined){
                  handleUpdate(key, "value", newValue?.value);
                }
              }
              }
              // onChange={(e) => handleUpdate(key, "name", e.target.value)}
              // value={fieldData.name} />
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      helperText={!!(messages[key]?.value)}
                      error={!!(messages[key]?.value)}
                      required
                      size="small"
                  />
              )}
          />
        </Table.Cell>
        <Table.Cell>
          <ParameterDeleteBtn idx={key} handleDelete={handleDelete} />
        </Table.Cell>
      </Table.Row>

  ))}
  </>
}
export const ParameterDeleteBtn = ({idx, handleDelete}: { idx:number, handleDelete:(key: number)=>void})=>{
  return <Button size="mini" icon  onClick={() => handleDelete(idx)}>
    <Icon  name='trash' />
  </Button>
}
export const ParameterInputCellsTextInputVariant = ({fields, messages, handleUpdate, handleDelete}: InputComponentProps) =>{
  return <>{fields.map((fieldData, key) => (
      <Table.Row key={key}>
        <Table.Cell>
          <Input
              style={{width: "100%"}}
              error={!!(messages[key]?.name)}
              required
              size="mini"
              onChange={(e) => handleUpdate(key, "name", e.target.value)}
              value={fieldData.name} />
        </Table.Cell>
        <Table.Cell>
          <Input
              style={{width: "100%"}}
              error={!!(messages[key]?.value)}
              required
              size="mini"  onChange={(e) => handleUpdate(key, "value", e.target.value)} value={fieldData.value} />
        </Table.Cell>
        <Table.Cell>
          <ParameterDeleteBtn idx={key} handleDelete={handleDelete} />
        </Table.Cell>
      </Table.Row>

  ))}
  </>
}

function flattenRecordSet<T>(givenRecord: Record<string, Set<T>>): Record<string, T[]>{
  return Object.keys(givenRecord).map((k)=>{
    return {[k]: Array.from(givenRecord[k])}
  }).reduce((a,b)=>({...a, ...b}), {});
}
