import {useCompanyId} from "../../core";
import {
  AMCompanyModel,
  AmProject,
  isFinalStateOfScenario,
  ProjectVersion,
  WhatIfScenarioConfig,
  WhatIfScenarioRecord
} from "../../ps-types";
import React, {useEffect, useState} from "react";
import {getWhatIfScenario, runWhatIfScenario, updateWhatIfScenario} from "./whatIfScenarios.client";
import {Button, Grid, Input, Segment, Select} from "semantic-ui-react";
import {ProjectVersionSelection} from "../projects/ProjectVersionSelection";
import {LoadingBlock} from "../../ui/Loading";
import {useMessages} from "../../ui/MessagesProvider";
import {getAmProjects} from "../projects/amProject.client";
import {ProjectVersionInputs} from "./ProjectVersionInputs";
import {GlobalInputs} from "./GlobalInputs";
import {WhatIfScenarioLogs} from "./WhatIfScenarioLogs";
import {getAmCompanyModels, getWhatIfScenarioConfig} from "../../company/company.client";
import {NewProjectSelection} from "./NewProjectSelection";
import {usePopup} from "../../ui/popup/Popup";
import {ContentPopup} from "../../ui/popup/ContentPopup";
import {EditorParam, MultiParameterEditor} from "../projects/MultiParameterEditor";
import {mapObjIndexed} from "ramda";
import {convertToNumberOrString} from "../../ps-models";
import {ValueType} from "../../ps-models/line-items";

export function WhatIfScenarioDetailPage({scenarioId}: {scenarioId: string}) {

  const [projects, setProjects] = useState<AmProject[]>(); // [projects, setProjects
  const [models, setModels] = useState<AMCompanyModel[]>();
  const [newProjects, setNewProjects] = useState<AmProject[]>();
  const [scenario, setScenario] = useState<WhatIfScenarioRecord>();
  const [config, setConfig] = useState<WhatIfScenarioConfig>();
  let [params, setParams] = useState<Record<string, EditorParam>>({});
  const [scenarioStatus, setScenarioStatus] = useState<WhatIfScenarioRecord['status']>();
  const {openPopup, closePopup} = usePopup()

  const {info, error, clear} = useMessages();

  const companyId = useCompanyId();

  useEffect(() => {
    async function load() {
      let scenario =  await getWhatIfScenario(companyId, scenarioId);
      let config =  getWhatIfScenarioConfig(companyId, scenario.configurationName);
      let projects =  getAmProjects(companyId);
      let models = await getAmCompanyModels(companyId);
      setScenario(scenario);
      setScenarioStatus(scenario.status);
      setConfig(await config);
      setProjects(await projects);
      setModels(models);
    }

    load().catch(e => error(e.message));

  }, [scenarioId]);


  if (!scenario) return <LoadingBlock/>;

  const updateScenario = async () => {
    let paramsObject: Record<string, ValueType> | undefined = mapObjIndexed((param) => convertToNumberOrString(param.value), params) ?? undefined;
    for(const [key, value] of Object.entries(paramsObject)){
      if(key === '' && value === ''){
        delete paramsObject[key];
      }
    }
    if(Object.keys(paramsObject).length === 0){
      paramsObject = undefined;
    }
    try {
      await updateWhatIfScenario(companyId, scenarioId, {
        projectVersions: scenario.inputData.projectVersions,
        globalInputs: scenario.inputData.globalInputs,
        parameters: paramsObject,
        projectVersionInputs: scenario.inputData.projectVersionInputs,
        scenarioName: scenario.scenarioName,
        modelId: scenario?.modelId ?? undefined,
      })
    } catch (e: any) {
      error(`Error updating scenario. ${e.message}`);
      throw e;
    }
  }


  const runScenario = async () => {
    clear();
    if(scenario.type === "new-projects" && !scenario.modelId) {
      error(`Please select a model to run scenario of type new-projects`)
      return;
    }
    await updateScenario();

    await runWhatIfScenario(companyId, scenarioId)
      .then(() => {
        info("Scenario started")
        setScenarioStatus("started");
      })
      .catch((e) => error(`Error running scenario. ${e.message}`));

  }

  const handleProjectVersionsChange = (selectedProjectVersions: ProjectVersion[]) => {
    console.info("Selected project versions", selectedProjectVersions)
    setScenario({...scenario, inputData: {...scenario.inputData, projectVersions: selectedProjectVersions}})
  }

  const handleUpdateScenarioName = (name: string) => {
    setScenario({...scenario, scenarioName: name})
  }

  const handleUpdateScenarioModelId = (modelId: string) => {
    setScenario({...scenario, modelId})
  }


  const handleScenarioInputUpdate = (data:  WhatIfScenarioRecord['inputData']) => {
    setScenario({...scenario, inputData: data})
  }

  const handleParamsUpdate = (updatedParams: Record<string, EditorParam>, deleted: string[]) => {
    setParams(updatedParams);
  }

  const canRunScenario = scenarioStatus && (isFinalStateOfScenario(scenarioStatus) || scenarioStatus === "new");

  const newProjectSelectionPopup = () => {
    openPopup(<ContentPopup size="small" onClose={closePopup} header={"Select Project"}  >
      <NewProjectSelection
        selectedProjects = {scenario.inputData.projectVersions}
        onSelection={(selectedProjects, newProjects) => {
          // console.info("Selected Projects", selectedProjects)
          // console.info("New Projects", newProjects)
          setNewProjects(newProjects);
          handleProjectVersionsChange(selectedProjects);
        }}
      />
    </ContentPopup> )
  }

  //What If Scenario Detail
  return <Segment style={{padding: "50px"}}>
    <div style={{position:'absolute', top: 10, left: 10}}>
      <Button basic labelPosition='left' icon='left chevron' size={"mini"} onClick={() => window.history.back()}
       content='Back'
      />
    </div>

    <h1>What If Scenario Detail</h1>
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <strong>Scenario Name</strong>
        </Grid.Column>
        <Grid.Column>
          <Input value={scenario.scenarioName}
                 onChange={(e) => handleUpdateScenarioName(e.target.value)}/>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <strong>Configuration Name</strong>
        </Grid.Column>
        <Grid.Column>
          {scenario.configurationName}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <strong>Status</strong>
        </Grid.Column>
        <Grid.Column>
          {scenarioStatus}
        </Grid.Column>
      </Grid.Row>

      {['project-version', 'global'].includes(scenario.type) && <Grid.Row columns={2}>
        <Grid.Column>
          <strong>Project Version</strong>
        </Grid.Column>
        <Grid.Column>
          <ProjectVersionSelection
            projects={projects}
            selectedProjectVersions={scenario.inputData.projectVersions}
            handleChange={handleProjectVersionsChange}
          />
        </Grid.Column>
      </Grid.Row>}

      { scenario.type === "new-projects" && <><Grid.Row columns={2}>
          <Grid.Column>
              <strong>Projects</strong>
          </Grid.Column>
          <Grid.Column>
              <Button content={"Select Projects"} onClick={newProjectSelectionPopup} />
          </Grid.Column>
      </Grid.Row>
        <Grid.Row columns={2}>
        <Grid.Column>
          <strong>Model</strong>
        </Grid.Column>
        <Grid.Column>
          <Select style={{marginBottom: "10px"}}
                  value={scenario?.modelId}
                  onChange={(e, data) => handleUpdateScenarioModelId(data.value as string)}
                  options={models?.map((it)=>{return {key:it.id, value: it.id, text: it.title}}) ?? []} placeholder='Select Model' />
        </Grid.Column>
      </Grid.Row></>}


    </Grid>
    <Segment>
      <MultiParameterEditor
          params={params} onUpdate={handleParamsUpdate} variant={'autocomplete'} />
    {scenario.type === "global" && config && <GlobalInputs
        config={config}
        inputData={scenario.inputData}
        updateInputData={handleScenarioInputUpdate}
    />}

    {scenario.type === "project-version" && config && projects && <ProjectVersionInputs
        config={config}
        inputData={scenario.inputData}
        updateInputData={handleScenarioInputUpdate}
        projects={projects}
    />}

      {scenario.type === "new-projects" && config && newProjects && <ProjectVersionInputs
          config={config}
          inputData={scenario.inputData}
          updateInputData={handleScenarioInputUpdate}
          projects={newProjects}
      />}

    <div>
      <Button onClick={updateScenario}>Save</Button>
      <Button disabled={!canRunScenario} onClick={runScenario} primary>Run</Button>
    </div>
    </Segment>
    <Segment>
    <h2>Logs</h2>
    {projects && <WhatIfScenarioLogs scenarioId={scenarioId}
                                     projects={projects}
                                     scenario={scenario}
                                     updateScenarioStatus={setScenarioStatus}
    />}
    </Segment>
  </Segment>
}