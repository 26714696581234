import {LineItem} from "./LineItem.model";
import { LineItemValue, ValueType} from "./LineItemValue.model";
import {TimeStream} from "../Time.model";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {TimeRange, TimeUnit} from "../Time.types";
import {LineItemField, PARAMETER_LINE_ITEM_TYPE} from "../../ps-types"
import {PartialExecution} from "../lineitems-store/PartialExecution";
import {AggregatorMethod} from "../line-item-utils/aggregators";

export function isParameterLineItem(li: LineItem): boolean {
  return li instanceof ParameterLineItem
}
export interface SerializedParameterLineItem {
  type: string,
  name: string,
  fields: Record<string, LineItemField>,
  value: ValueType | undefined
}

export class ParameterLineItem extends LineItem {

  public timeStream = new TimeStream<LineItemValue>();

  getValue(): LineItemValue {
    return this.timeStream.get(0) || new LineItemValue(0)
  }

  setValue(value: ValueType) {
    this.timeStream.add(0, new LineItemValue(value))
  }

  add(t: TimeUnit, value: ValueType): this {
    this.timeStream.add(0, new LineItemValue(value))
    return this
  }

  get type(): string {
    return PARAMETER_LINE_ITEM_TYPE
  }

  serialize() {
    return {
      type: this.type,
      name: this.name,
      fields: this.fields.serialize(),
      value: this.timeStream.get(0)?.value
    }
  }

  clone() {
    return buildParameterLineItem(this.name, this.getValue().value, this.fields.clone())
  }

  static deserialize(li: any) {
    return buildParameterLineItem(li.name, li.value,  LineItemsFieldSet.deserialize(li.fields))
  }

  getTotal(): ValueType {
    return this.getValue().value;
  }
}

export function buildParameterLineItem(name: string, value: ValueType, fields: Record<string, ValueType> | LineItemsFieldSet = {}) {
  if(fields instanceof LineItemsFieldSet) {
    return new ParameterLineItem(name, fields).add(0, value);
  }
  return new ParameterLineItem(name, LineItemsFieldSet.fromMap(fields)).add(0, value);
}

export function buildParameterLineItemFromMap(map: Record<string, ValueType>, fields: Record<string, string> | LineItemsFieldSet = {}) {
  return Object.entries(map).map(([name, value]) => buildParameterLineItem(name, value, fields))
}