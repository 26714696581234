import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    TextField,
} from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import {Button, Icon} from "semantic-ui-react";
import {TimeZoneContext} from "../../ps-models/TimeZoneContext.types";

type TimeZoneContextOptionType = (Omit<TimeZoneContext, 'name'> & {label: string, value: string});
const timeZoneContextOptions: TimeZoneContextOptionType[] = [
    { label: "UTC (UTC+00:00)", value: "UTC (UTC+00:00)" },
    { label: "Asia/Kolkata (UTC+05:30)", value: "Asia/Kolkata (UTC+05:30)", timezoneOffset: 0, granularityWiseAggregationOffsetMapping: {months: 3} },
    { label: "Asia/Kolkata (UTC+05:30) (Financial Period)", value: "Asia/Kolkata (UTC+05:30) (FY)", timezoneOffset: 0, granularityWiseAggregationOffsetMapping: {months: 3}, timeLineLabellingStrategy: 'financial' },
];

const buildTimeZoneContextOption = (tzCtxOpt: TimeZoneContext): TimeZoneContextOptionType=>{
    return { ...tzCtxOpt, label: tzCtxOpt.name, value: tzCtxOpt.name };
}

export default function TimeZoneContextSelector({initialTimeZoneContext,onUpdate}: {initialTimeZoneContext: TimeZoneContext | undefined, onUpdate:(updatedTimeZoneContext: TimeZoneContext | undefined)=>void}) {
    const [selectedTimeZoneContextOption, setSelectedTimeZoneContextOption] = useState<TimeZoneContextOptionType | undefined>(initialTimeZoneContext ? buildTimeZoneContextOption(initialTimeZoneContext) : timeZoneContextOptions[0]);

    const handleTimeZoneContextChange = (event: any, newValue: TimeZoneContextOptionType | null) => {
        setSelectedTimeZoneContextOption(newValue ?? undefined);
    };

    return (
        <Card variant="outlined" sx={{ maxWidth: 600, margin: "0 auto" }}>
            <CardHeader
                title="Date & Time Settings"
                subheader="Customize your data viewing context"
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={timeZoneContextOptions}
                            getOptionLabel={(option) => option.label}
                            value={selectedTimeZoneContextOption}
                            onChange={handleTimeZoneContextChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Time Zone"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <Button primary onClick={()=>{
                let tzCtx = undefined;
                if(selectedTimeZoneContextOption){
                    const {label, value, ...timezoneCtx} = selectedTimeZoneContextOption;
                    tzCtx = {...timezoneCtx, name: label};
                }
                onUpdate(tzCtx)
            }}
            >Apply</Button>
        </Card>
    );
}
