import {useUpdateContext} from "../../UpdateContext";
import {useSiteStoreContext} from "../siteStoreLoader";
import React from "react";
import {
  buildDailyTimeDef,
  buildParameterLineItem,
   buildTimedCalculatedLineItem, buildTimedRawLineItem,
  LineItem,
} from "../../ps-models/line-items";
import {Button, Input, Select} from "semantic-ui-react";
import {buildTemplateLineItem} from "../../ps-models/line-items/TemplateLineItem";
import {
  PARAMETER_LINE_ITEM_TYPE,
  TIMED_CALCULATED_LINE_ITEM_TYPE, TIMED_RAW_LINE_ITEM_TYPE,
  TEMPLATE_LINE_ITEM_TYPE} from "../../ps-types";



export function LineItemAdder({setMessage}: {setMessage: (message:string)=>void}) {
  const ctx = useUpdateContext();
  const store = useSiteStoreContext();


  let [newLineItemName, setNewLineItemName] = React.useState<string>("");
  const [newParameterValue, setNewParameterValue] = React.useState<string>("")
  let [newLineItemType, setNewLineItemType] = React.useState<string>(TIMED_RAW_LINE_ITEM_TYPE);

  let lineItemOptions = [
    {key: TIMED_CALCULATED_LINE_ITEM_TYPE, text: "Calculated", value: TIMED_CALCULATED_LINE_ITEM_TYPE},
    {key: PARAMETER_LINE_ITEM_TYPE, text: "Parameter", value: PARAMETER_LINE_ITEM_TYPE},
    {key: TIMED_RAW_LINE_ITEM_TYPE, text: "Raw", value: TIMED_RAW_LINE_ITEM_TYPE},
  ]

  if(store.isTemplate){
    lineItemOptions.push({key: TEMPLATE_LINE_ITEM_TYPE, text: "Template", value: TEMPLATE_LINE_ITEM_TYPE})
  }



  function addLineItem() {

    if (!newLineItemName) return;

    let li: LineItem;

    let timeDef;

    switch (store.timeIndex.getUnit()) {
      case "hours":
        timeDef = buildDailyTimeDef();
        break;
      case "days":
        timeDef = buildDailyTimeDef();
        break;
      case "months":
        timeDef = buildDailyTimeDef();
        break;
      case "years":
        timeDef = buildDailyTimeDef();
        break;
      default:
        timeDef = buildDailyTimeDef();
        break;

    }

    switch (newLineItemType) {
      case TIMED_RAW_LINE_ITEM_TYPE:
        li = buildTimedRawLineItem(newLineItemName, timeDef);
        break;
      case PARAMETER_LINE_ITEM_TYPE:
        li = buildParameterLineItem(newLineItemName, newParameterValue);
        break;
      case TEMPLATE_LINE_ITEM_TYPE:
        li = buildTemplateLineItem(newLineItemName, timeDef)
        break;
      default:
      case TIMED_CALCULATED_LINE_ITEM_TYPE:
        li = buildTimedCalculatedLineItem(newLineItemName, timeDef, "1 + 2");
        break;
    }
    // if(store.getDataSet().getLineItem(li.name)) {
    //   // eslint-disable-next-line no-restricted-globals
    //   if(!confirm("A line item with this name already exists. Do you want to overwrite it?")) {
    //     return;
    //   }
    // }
    // if(newLineItemType === TEMPLATE_LINE_ITEM_TYPE){
      // store.getDataSet().addTemplateLineItem(li);
    // } else {
      store.getDataSet().addLineItem(li);
    // }
    ctx.ready();
  }

  return <div>
    <Input placeholder="Line Item Name" onChange={(e, data) => setNewLineItemName(data.value)}/>
    {newLineItemType === PARAMETER_LINE_ITEM_TYPE && <Input placeholder="Parameter Value" onChange={(e, data) => setNewParameterValue(data.value)}/>}
    <Select options={lineItemOptions} onChange={(e, data) => {
      console.info("Selected ", data)
      data.value && setNewLineItemType(data.value.toString())
    }} placeholder="Line Item Type"/>
    <Button onClick={addLineItem}> Add Line Item</Button>
  </div>
}