import {LineItem, } from "./LineItem.model";
import { LineItemValue, NO_DATA, ValueType} from "./LineItemValue.model";
import {
    getTimeUnitIndex,
    TimeStream
} from "../Time.model";
import {AggregatorMethod, runAggregatorMethod} from "../line-item-utils/aggregators";
import {LineItemsFieldSet} from "./LineItemsFieldSet";
import {TimedLineItem} from "./TimedLineItem";
import {runSpreadMethod, TimeDefinition} from "./TimeDefinition";
import {
    addTime,
    buildTimeRangeFromGranularity,
    isLowerGranularity, rangeMap,
} from "../TimeGranularity";
import {TimeRange, TimeUnit, TimeUnits} from "../Time.types";
import {entries} from "../line-item-utils/coding.utils";
import {PartialExecution} from "../lineitems-store/PartialExecution";
import {mapObjIndexed, filter} from "ramda";
import {DomainError} from "../exceptions";
import {REPEATING_LINE_ITEM} from "../../ps-types";


export function buildRepeatingLineItem(name: string,
                                  timeDefinition: TimeDefinition,
                                  fields: LineItemsFieldSet = new LineItemsFieldSet()
): RepeatingLineItem {
    return new RepeatingLineItem(name, timeDefinition, fields)
}

export class RepeatingLineItem extends TimedLineItem {

    _discriminator: "timed" = "timed";
    public timeStream = new TimeStream<LineItemValue>();
    constructor(public name: string,
                public timeDefinition: TimeDefinition,
                public fields: LineItemsFieldSet
    ) {

        super(name, fields);
    }

    getTimeDefinition(): TimeDefinition {
        return this.timeDefinition;
    }

    getValue(timeQuery: TimeUnit, report: PartialExecution, targetGranularity?: TimeUnits): LineItemValue {

        if(!targetGranularity) {
            targetGranularity = this.timeDefinition.granularity;
        }

        if(targetGranularity === this.timeDefinition.granularity) {
            const timeUnitIndex = getTimeUnitIndex(new Date(timeQuery), this.timeDefinition.granularity);
            let existingValue = this.timeStream.get(timeUnitIndex);

            if(existingValue) {
                return existingValue;
            } else {
                return NO_DATA;
            }
        }

        if(isLowerGranularity(targetGranularity, this.timeDefinition.granularity)) {
            //TODO: Using "repeat" spread strategy, support other spreaders
            targetGranularity = this.timeDefinition.granularity;
        }


        let range = buildTimeRangeFromGranularity(timeQuery, this.timeDefinition.granularity, targetGranularity, report?.getTimeZoneContext());

        const values: ValueType[] = [];

        for(let r of rangeMap(range, this.timeDefinition.granularity)) {
            let rIndex = getTimeUnitIndex(new Date(r), this.timeDefinition.granularity);
             values.push(this.timeStream.get(rIndex)?.value || 0);
        }

        if(values.length === 0) {
            return NO_DATA;
        }

        return new LineItemValue(runAggregatorMethod(this.timeDefinition.aggregator, values));
    }


    add(timeStamp: number | Date, value: ValueType, lowerFrequencyAggregator?: AggregatorMethod): RepeatingLineItem {

        if(value === null || value === undefined) {
            return this;
        }

        if(timeStamp instanceof Date) {
            timeStamp = timeStamp.getTime();
        }

        let index = getTimeUnitIndex(new Date(timeStamp), this.timeDefinition.granularity);

        return this.addTimeIndexedValue(index, value);
    }

    addTimeIndexedValue(index: number, value: ValueType): RepeatingLineItem {

        const granularity = this.timeDefinition.granularity;

        if(index < 0) {
            throw new DomainError("Index must be greater than or equal to zero");
        }

        if(granularity === "days") {

            if(index > 31) {
                throw new DomainError("Day index must be between 0 and 31");
            }

            this.timeStream.add(index, new LineItemValue(value));
        } else if(granularity === "months") {

            if(index > 11) {
                throw new DomainError("Month index must be between 0 and 11");
            }

            this.timeStream.add(index, new LineItemValue(value));
        } else if(granularity === "quarters") {

              if(index > 3) {
                  throw new DomainError("Quarter index must be between 0 and 3");
              }

              this.timeStream.add(index, new LineItemValue(value));
        }


        return this;
    }

    clone(): LineItem {
        let newLi = new RepeatingLineItem(this.name, this.timeDefinition, this.fields.clone());
        // @ts-ignore
        newLi.timeStream = this.timeStream.clone();
        return newLi;
    }

    get type(): string {
        return REPEATING_LINE_ITEM
    }

    serialize() {

        let serializedTimeStream = this.timeStream.serialize();

        //Filter out the values that are zero from serialized time stream
        let filteredTimeStream = filter((v) => {
            return !!v.value;
        }, serializedTimeStream);

        filteredTimeStream = mapObjIndexed((v, k) => {
            return {value: v.value}
        }, filteredTimeStream)

        return {
            type: this.type,
            name: this.name,
            fields: this.fields.serialize(),
            timeStream: filteredTimeStream,
            timeDefinition: this.timeDefinition.serialize()
        }
    }

    withDefinition(timeDefinition: TimeDefinition) {
        let timedRaw =  new RepeatingLineItem(this.name, timeDefinition, this.fields.clone());

        for(let [timeStamp, value] of entries(this.timeStream.timeStream)) {
            timedRaw.add(parseInt(timeStamp), value.value);
        }

        return timedRaw;
    }

    shiftTime(timeUnit: TimeUnits, amount: number) {
        let newLineItem = new RepeatingLineItem(this.name, this.timeDefinition, this.fields.clone());

        for(let [timeStamp, value] of entries(this.timeStream.timeStream)) {
            newLineItem.add(addTime(new Date(parseInt(timeStamp)), amount, timeUnit), value.value);
        }

        this.timeStream = newLineItem.timeStream;
    }

    getValues() {
        return this.timeStream.getValues().map(v => v.value);
    }

    getTimes() {
        return this.timeStream.getTimes();
    }

    getTotal({start, end}: TimeRange, report: PartialExecution, aggregator?: AggregatorMethod): ValueType {
        let values = this.timeStream.getRange({start, end}, this.timeDefinition.granularity)
          .map(v => v.value);

        if(values.length === 0) {
            return 0;
        }

        return runAggregatorMethod(aggregator || this.timeDefinition.aggregator, values);
    }


    static deserialize(li: any) {
        const raw = new RepeatingLineItem(li.name, TimeDefinition.deserialize(li.timeDefinition ), LineItemsFieldSet.deserialize(li.fields))
        raw.timeStream = TimeStream.deserialize(li.timeStream)
        return raw
    }
}

