import {LineItemsStore} from "../../ps-models/lineitems-store";
import {authStorage} from "../../auth";
import {getAmProjectConfig} from "../../ps-models";
import {SectionService} from "../Section";
import {ReactNode} from "react";

export interface CompanyModule {


    onStoreInit(store: LineItemsStore, dashboardId: string): void;

    overrideMainMenu(location: string, mainMenu:  { key: string, menuItem: ReactNode }[], companyId: string, history: any):  { key: string, menuItem: ReactNode }[] ;

    overrideDashboardSections(sectionService: SectionService): SectionService;

    getRoutes(): ReactNode;

    overrideUIStrings(): Record<string, string>;
}

const DashboardConfigServiceRegistry: {
    [key: string]: CompanyModule
} = {}


export function registerModule(moduleName: string, def: CompanyModule) {
    DashboardConfigServiceRegistry[moduleName] = def;
}

export class DefaultModule implements CompanyModule {
    getRoutes(): ReactNode { return null; }
    onStoreInit(store: LineItemsStore, dashboardId: string) {}
    overrideDashboardSections(sectionService: SectionService) {  return sectionService; }
    overrideUIStrings(): Record<string, string> { return {}; }
    overrideMainMenu(location: string, mainMenu:{ key: string, menuItem: ReactNode }[], companyId: string, history: History ) { return mainMenu; }
}


export function loadCompanyModule() {
    const company = authStorage.getCompany();
    let { namespace } = getAmProjectConfig(company);

    return DashboardConfigServiceRegistry[namespace] || new DefaultModule();
}

export function useCompanyModule() {
    return loadCompanyModule();
}