import {FinancialValueType} from "../../../ps-models";
import {BuilderContext, registerWidgetType} from "../../builder/WidgetRegistry";
import React from "react";
import {IncomingPayments} from "../../IncomingPayments";
import {useOnStoreReady, useUpdateOnGlobalContextChange} from "../../builder/widgets/commons";
import {SendToTurnKeyButton} from "./SendToTurnKeyButton";

interface ConfigProps {
  label: string,
  type: FinancialValueType,
  addToStore?: boolean,
}

registerWidgetType({
    typeId: 'CGCDemo-incoming-payments',
    metadata: {
      name: 'CGCDemo Incoming Payments',
      description: '',
      icon: 'app',
    },
    defaultConfig: {
      label: 'Param',
      type: "number",
      addToStore: false,
    },
    renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
      return  <></>
    },
    render: (config: any, context: BuilderContext) => {
      return  <IncomingPaymentsWidget context={context}/>
    }
  }
)

function IncomingPaymentsWidget({context}:{context: BuilderContext}) {
  useOnStoreReady(context);
  useUpdateOnGlobalContextChange(context);

  return  <IncomingPayments
      includeLoanColumn
    store={context.appContext.getStore()}
  />

}


registerWidgetType({
        typeId: 'CGCDemo-send-turnkey-button',
        metadata: {
            name: 'CGCDemo Send Button',
            description: '',
            icon: 'phone volume',
        },
        defaultConfig: {},
        renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
            return  <></>
        },
        render: (config: any, context: BuilderContext) => {
            return  <SendToTurnKeyButton text="Send Report" />
        }
    }
)
